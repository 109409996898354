<template>
  <div
    class="content-block"
    @mouseenter="toggleHover"
    @mouseleave="toggleHover"
  >
    <component
      :is="props.content.route ? NuxtLink : 'div'"
      :to="props.content?.route ? localePath(props.content?.route) : undefined"
      class="content-block"
    >
      <div class="content-block__image">
        <template v-if="props.content?.cover?.length > 1">
          <swiper
            class="content-block__swiper"
            :navigation="{
              nextEl: nextButton,
              prevEl: prevButton,
            }"
            :modules="[A11y, Navigation]"
            :lazy="true"
          >
            <swiper-slide v-for="image in props.content.cover" :key="image?.id">
              <div
                class="content-block-swiper__slide__wrap"
                :data-hoverable="
                  props.content.route ? $t('cursor.view') : undefined
                "
              >
                <WidgetPicture
                  :image="{
                    custom: image,
                  }"
                  :lazyLoad="true"
                  :alt="image?.title"
                />
              </div>
            </swiper-slide>
            <!-- Navigation  -->
            <div
              class="swiper-buttons content-block-swiper__buttons"
              v-if="props.content.cover?.length > 1"
            >
              <button
                class="swiper-button-prev"
                ref="prevButton"
                @click="onClickPrevent()"
                aria-label="Button Prev"
                :data-hoverable="undefined"
              ></button>
              <button
                class="swiper-button-next"
                ref="nextButton"
                @click="onClickPrevent()"
                aria-label="Button Next"
                :data-hoverable="undefined"
              ></button>
            </div>
          </swiper>
        </template>
        <template v-else>
          <div
            :data-hoverable="
              props.content.route ? $t('cursor.view') : undefined
            "
          >
            <WidgetPicture
              :image="{
                custom: props.content.cover?.[0] || props.content.cover,
              }"
              :alt="
                props.content.cover?.[0]?.title || props.content.cover?.title
              "
              :lazyLoad="true"
            />
          </div>
        </template>
      </div>
      <!-- Testo Negativo limitato a wrap immagine -->
      <div
        :data-hoverable="props.content.route ? $t('cursor.view') : undefined"
        class="content-block__text m-top-16px"
        v-if="props.content?.title"
      >
        <p
          class="text-xs text-uppercase m-bot-8px"
          v-if="props.content.pretitle"
        >
          {{ props.content.pretitle }}
        </p>
        <h2
          class="text-m"
          :class="{
            'link-underline-reverse-1': props.content.route,

            active: hovered,
          }"
        >
          <span>{{ props.content.title }}</span>
        </h2>
        <p class="text-xs m-top-8px" v-if="props.content.aftertitle">
          {{ props.content.aftertitle }}
        </p>
      </div>
    </component>
  </div>
</template>

<script>
export default {
  name: 'WidgetContentBlock',
}
</script>

<script setup>
import { NuxtLink } from '#components'
import { A11y, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'

const props = defineProps({
  content: Object,
})

const hovered = ref(false)
const touch = ref(false)

const toggleHover = () => {
  hovered.value = !hovered.value && touch.value
}

const prevButton = ref(null)
const nextButton = ref(null)

const onClickPrevent = (e) => {
  e?.stopPropagation()
}

onMounted(() => {
  touch.value = 'ontouchstart' in window || navigator.maxTouchPoints
})
</script>

<style lang="scss">
@import './style.scss';
</style>
